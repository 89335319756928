import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User2/Documents/flits-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "asset-management"
    }}>{`Asset Management`}</h1>
    <p>{`Fitur Asset Management memungkinkan user untuk melacak aset tetap user, seperti mesin, tanah dan bangunan. Fitur ini dapat men generate jurnal depresiasi bulanan maupun tahunan secara otomatis, menyajikan tabel depresiasi, melakukan dispose pada aset tertentu serta dapat menyajikan laporan aset perusahaan.`}</p>
    <p>{`Sebelum membuat data aset perusahaan, harus dibuat terlebih dahulu aset kategorinya`}</p>
    <h2 {...{
      "id": "kategori-asset"
    }}>{`Kategori Asset`}</h2>
    <p>{`Kategori Aset digunakan untuk mengkonfigurasi semua informasi tentang Asset : Akun Aset dan Metode Depresiasi & Amortisasi aset. Dengan begitu, penasihat dapat mengonfigurasi jenis aset dan pengguna dapat merekam aset lebih lanjut tanpa harus memberikan informasi akuntansi yang rumit. Mereka hanya perlu memberikan jenis aset pada tagihan pemasok.`}</p>
    <h2 {...{
      "id": "formulir-kategori-asset"
    }}>{`Formulir Kategori Asset`}</h2>
    <img src='/public/img/Asset Management/Form Kategori Asset.png' />
    <h3 {...{
      "id": "kolom-kolom-pada-kategori-aset-detail"
    }}>{`Kolom-kolom pada Kategori Aset Detail`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Nama Kategori Aset`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memberi nama pada kategori aset`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Durasi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field mengatur Banyaknya jumlah dan periodisasi depresiasi aset`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Akun Aktiva Tetap`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih akun aktiva tetap pada kategori tersebut (Field ini diberi batasan untuk akun yang dapat dipilih adalah akun yang bertipe Aktiva Tetap)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Akun Penyusutan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih akun penyusutan pada kategori tersebut (Field ini diberi batasan untuk akun yang dapat dipilih adalah akun yang bertipe Aktiva Tetap)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Akun Biaya`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih akun biaya pada kategori tersebut (Field ini diberi batasan untuk akun yang dapat dipilih adalah akun yang bertipe depresiasi & amortisasi)`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "membuat-kategori-aset"
    }}>{`Membuat Kategori Aset`}</h3>
    <p>{`Berikut merupakan langkah-langkah dalam pembuatan Kategori Aset yang dapat digunakan untuk mengkonfigurasi Aset :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Kategori Aset dengan klik Accounting Pada Side Menu > Management Asset > Kategori Aset Pada list Kategori Aset klik Tombol Create.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Isi Formulir Kategori Aset seperti gambar berikut :`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Masukkan nama Kategori Aset yang akan dibuat`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Atur Jumlah Depresiasi serta Periodisasi Depresiasi Kategori Aset tersebut`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih Akun Aktiva Tetap, Akun Penyusutan dan Akun Biaya`}</p>
            <img src='/public/img/Asset Management/Bikin Kategori Asset.png' />
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field terisi, klik Save untuk menyimpan formulir kedalam database.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "formulir-aset"
    }}>{`Formulir Aset`}</h2>
    <img src='/public/img/Asset Management/Form Asset.png' />
    <h3 {...{
      "id": "kolom-kolom-pada-aset-detail"
    }}>{`Kolom-kolom pada Aset Detail`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Nama Aset`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan Nama Aset`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Kategori Aset`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Berisi semua list kategori aset`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl. Perolehan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih tanggal diperolehnya aset`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl. Pertama Depresiasi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menentukan tanggal pertama depresiasi aset`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Harga Perolehan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan Harga Aset saat dibeli oleh perusahaan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Nilai Sisa`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan Harga Aset setelah depresiasi dalam kurun periode tertentu`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Nilai Residu`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Menghitung Nominal Harga aset yang terdepresiasi dihitung dari Harga Perolehan - Nilai Sisa`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "membuat-aset"
    }}>{`Membuat Aset`}</h2>
    <h3 {...{
      "id": "membuat-aset-secara-manual"
    }}>{`Membuat Aset Secara Manual`}</h3>
    <ul>
      <li parentName="ul">{`Buka Formulir Aset dengan klik Accounting Pada Side Menu > Management Asset > Aset Pada list Kategori Aset klik Tombol Create.`}</li>
      <li parentName="ul">{`Isi Formulir Aset seperti gambar berikut :`}
        <ol type="a">
    <li>Masukkan Nama Aset yang akan dibuat</li>
    <li>Pilih Kategori Aset</li>
    <li>Tentukan tanggal perolehan dan tanggal pertama depresiasi aset</li>
    <li>Masukkan Harga Perolehan aset</li>
    <li>Masukkan Nilai Sisa jika aset tersebut memiliki nilai sisa</li>
        </ol>
      </li>
    </ul>
    <img src='/public/img/Asset Management/Bikin Asset.png' />
    <ul>
      <li parentName="ul">{`Setelah semua field terisi, klik Save untuk menyimpan formulir kedalam database.`}</li>
      <li parentName="ul">{`Saat pertama kali aset terbentuk, aset masih berstatus Draft yang artinya aset tersebut belum sepenuhnya diakui sebagai Aset Perusahaan sehingga untuk jurnal depresiasi tidak akan terbentuk juga.`}</li>
    </ul>
    <h3 {...{
      "id": "membuat-aset-secara-otomatis-dari-vendor-bill"
    }}>{`Membuat Aset Secara Otomatis dari Vendor Bill`}</h3>
    <p>{`Aset dapat dibuat secara otomatis melalui Vendor Bill. Anda hanya dapat memilih akun pada vendor Bill sesuai Akun Aktiva tetap yang telah dikonfigurasi pada Kategori Aset. Kemudian ketika Vendor Bill di Konfirmasi maka aset akan secara otomatis terbentuk sesuai data yang ada pada Vendor Bill`}</p>
    <img src='/public/img/Asset Management/Bikin Asset dari Bill.png' />
    <h2 {...{
      "id": "depresiasi-aset"
    }}>{`Depresiasi Aset`}</h2>
    <p>{`Flits akan membuat jurnal depresiasi secara otomatis sesuai tanggal yang telah ditentukan setiap selesai melakukan konfirmasi aset. Anda dapat melakukan kontrol terhadap tabel depresiasi. Jurnal Depresiasi hanya dapat di post secara manual, apabila tanggal depresiasi telah melebihi hari ini.`}</p>
    <img src='/public/img/Asset Management/Depresiasi Asset.png' />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      